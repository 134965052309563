import { type ReactNode, Suspense } from 'react'
import { type Product } from 'api-client'

import { type ProductIconNames, ProductIcons } from '~/shared/ui/ProductIcons'
import { Tag } from '~/shared/ui/Tag'

import { Box } from '~/components/Box'
import { Card } from '~/components/Card'
import { Icon } from '~/components/Icon'
import { Hexagon } from '~/components/Icons'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

export type ProductCardProps = {
  /**
   * Name of the product
   */
  name: Product['name']
  /**
   * Slug of the product
   */
  slug: Product['slug']
  /**
   * List of categories for the product
   */
  tags: Product['tags']
  /**
   * Short description of the product
   */
  description: Product['description']
  /**
   * Indicates if the product is available to use
   */
  isAvailable: Product['isAvailable']
  /**
   * Used to compose an interactive card with actionable component placed in the footer
   */
  children?: ReactNode
}

/**
 * Presentational component to display the information of a product
 */
export const ProductCard = ({
  name,
  slug,
  tags,
  description,
  isAvailable,
  children,
}: ProductCardProps) => {
  const ProductIcon = ProductIcons[slug as ProductIconNames] ?? Hexagon
  return (
    <Card>
      <Card.Section>
        <Stack direction="horizontal" justify="start" space="xsmall" align="center">
          <Icon size="xxxlarge">
            <Suspense>
              <ProductIcon />
            </Suspense>
          </Icon>
          <Text size="xlarge">{name}</Text>
        </Stack>

        <Box paddingY="xsmall">
          {tags && (
            <Stack direction="horizontal" justify="start" space="xsmall" align="center">
              {tags.map(({ tag }) => (
                <Tag key={tag}>{tag}</Tag>
              ))}
            </Stack>
          )}
          {isAvailable ? null : <Tag color="neutral">Soon</Tag>}
        </Box>
        <Box paddingTop="xsmall">
          <Text size="small" lineHeight={'medium'}>
            {description}
          </Text>
        </Box>
      </Card.Section>
      {children && <Card.Footer>{children}</Card.Footer>}
    </Card>
  )
}
