import * as React from 'react'
import { useFetcher } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { type ActionData } from '~/pages/settings/organization'

import { INVITE_USER } from '~/entities/organizations'
import { projectsQuery } from '~/entities/projects'

import { Box } from '~/components/Box'
import { Button } from '~/components/Button'
import { Input } from '~/components/Input'
import { MultiSelect } from '~/components/MultiSelect'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

/**
 * Form to invite a user to your organization.
 *
 * By default submits to the current route's action.
 * optionally you could invite user to a specific project by passing a project id
 */
export const InviteUserForm = () => {
  const fetcher = useFetcher<ActionData>()
  const { data: projectsFromOrg } = useQuery(projectsQuery())

  const projectsMapped = React.useMemo(() => {
    return (
      projectsFromOrg?.map(project => {
        return { text: project.name, value: project.id }
      }) ?? []
    )
  }, [projectsFromOrg])

  const submittedUserEmail = fetcher.formData?.get('email') as string
  const submittedProjects = fetcher.formData?.get('project_ids') as string

  function extractSelectedKeys(selectedKeys: Set<React.Key>) {
    const selectedProjects = []

    for (const key of selectedKeys) {
      const projectFound = projectsMapped?.find(project => project.value === key)

      selectedProjects.push(projectFound?.text)
    }
    return selectedProjects.join(', ')
  }

  return (
    <fetcher.Form method="post">
      <Box marginBottom={'medium'}>
        <Stack direction={'horizontal'} align={'start'} space={'medium'}>
          <MultiSelect
            key={submittedProjects}
            name="project_ids"
            label="Select Projects to add this new member"
            placeholder="Select projects"
            selectedText={(selectedKeys: Set<React.Key>) => extractSelectedKeys(selectedKeys)}
          >
            {projectsMapped?.map(project => (
              <MultiSelect.Item key={project.value} textValue={project.text}>
                <Text>{project.text}</Text>
              </MultiSelect.Item>
            ))}
          </MultiSelect>
        </Stack>
      </Box>
      <Box>
        <Stack direction="horizontal" align="start" space="small">
          <Box flex={1}>
            <Input
              key={submittedUserEmail}
              name="email"
              placeholder="Email address"
              errorMessage={
                fetcher.state === 'idle' && fetcher.data?.errors?.fieldErrors.email?.join(' ')
              }
              aria-label="invite new user"
              defaultValue={submittedUserEmail}
            />
          </Box>

          <Button
            color="brand"
            type="submit"
            name="intent"
            value={INVITE_USER}
            isDisabled={fetcher.state === 'submitting'}
            isLoading={fetcher.state === 'submitting'}
          >
            Invite
          </Button>
        </Stack>
      </Box>
    </fetcher.Form>
  )
}
