import { Suspense } from 'react'
import { type Integration, type Project } from 'api-client'

import { type ProductIconNames, ProductIcons } from '~/shared/ui/ProductIcons'

import { formatDate } from '~/i18n/utils'

import { Box } from '~/components/Box'
import { Card } from '~/components/Card'
import { Icon } from '~/components/Icon'
import { Link } from '~/components/Link'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

import { IntegrationStatus } from './IntegrationStatus'

type IntegrationCardProps = {
  /**
   * Name of the integration's product
   */
  name: Integration['product']['name']
  /**
   * Slug for the integration.
   */
  slug: Integration['product']['slug']

  /**
   * If the user is active
   */
  isUserActive?: boolean
  /**
   * Date when the integration was enabled to the project
   */
  createdAt: Integration['createdAt']
  /**
   * Status of the integration
   */
  status: Integration['status']
  /**
   * Current project id.
   */
  projectId: Project['id']
  /**
   * User who enabled the integration to the project
   */
  user: string
}

/**
 * Presentational component to display the information of an Integration
 */
export const IntegrationCard = ({
  name,
  slug,
  isUserActive,
  createdAt,
  status,
  projectId,
  user,
}: IntegrationCardProps) => {
  const ProductIcon = ProductIcons[name.toLowerCase() as ProductIconNames]
  const isInactive = name === 'hepic' && !isUserActive
  return (
    <Card fill>
      <Card.Section>
        <Stack direction="horizontal" justify="start" space="xsmall" align="center">
          <Icon size="xxxlarge">
            <Suspense>
              <ProductIcon />
            </Suspense>
          </Icon>
          <Text size="xlarge" color="white">
            {name}
          </Text>
        </Stack>
        {isInactive ? (
          <div style={{ height: '23px' }}>
            <Text size="small">
              Please ask your admin to add you as a user to this integration.
            </Text>
          </div>
        ) : (
          <IntegrationStatus projectId={projectId} slug={slug} status={status} />
        )}

        <Stack direction="vertical" space="small">
          <Box paddingTop="small">
            <Text size="small" color="gray600">{`Added by ${user}`}</Text>
            <Text size="small" color="gray600">{`${formatDate(createdAt, undefined, {
              dateStyle: 'short',
            })}`}</Text>
          </Box>

          {status === 'suspended' && (
            <Text size="small">
              <Link to="/billing" type="text">
                Review your payment method
              </Link>{' '}
              so your subscription to {name} can be reactivated.
            </Text>
          )}
        </Stack>
      </Card.Section>
    </Card>
  )
}
