import { Children, type ReactElement, useState } from 'react'
import { isFragment } from 'react-is'

import { Box } from '../Box'
import { Button } from '../Button'

import * as styles from './Wizard.css'

type WizardProps = {
  children: ReactElement | ReactElement[]
  initialPage?: number
}

export const Wizard = ({ children, initialPage = 1 }: WizardProps) => {
  const [activePage, setActivePage] = useState(initialPage)

  const pages = Children.toArray(isFragment(children) ? children.props.children : children)
  const currentPage = pages[activePage - 1]

  const prev = () => setActivePage(index => index - 1)
  const next = () => setActivePage(index => index + 1)

  return (
    <Box className={styles.wizard}>
      <Box className={styles.wizardWrapper}>
        <Box className={styles.wizardContent}>
          <Box padding="medium">{currentPage}</Box>
        </Box>
      </Box>
      <Box margin="small">
        <Button onPress={prev} isDisabled={activePage === 1}>
          Prev
        </Button>
        <Button color="accent" onPress={next} isDisabled={activePage === pages.length}>
          Next
        </Button>
      </Box>
    </Box>
  )
}
