import { type Product, type Project } from 'api-client'

import { ProductCard, type ProductCardProps } from '~/entities/products'

import { Button } from '~/components/Button'
import { Icon } from '~/components/Icon'
import { ChevronRight } from '~/components/Icons'
import { Link } from '~/components/Link'
import { Message } from '~/components/Message'
import { Stack } from '~/components/Stack'

import { AddToProject } from './AddToProject'

type ProductCardWithActionsProps = ProductCardProps & {
  /**
   * The id for the current project on context
   */
  projectId: Project['id']
  /**
   * The slug for the current project on context
   */
  projectSlug: Project['slug']
  /**
   * Indicates if the product has been added to the current project in context
   */
  isEnabled: boolean

  /**
   * Indicates if the current user is an organization account
   */

  isOrgAccount: boolean

  /**
   * Slug of the product used for navigation
   */
  slug: Product['slug']
  /**
   * List of unmet dependencies for this product
   */
  unmetDependencies: string[]
}

/**
 * Adds interactivity to a product's card so users can add it to a project or send us an email for unavailable ones
 */
export const ProductCardWithActions = ({
  name,
  slug,
  description,
  tags,
  isAvailable,
  isEnabled,
  isOrgAccount,
  projectId,
  projectSlug,
  unmetDependencies,
}: ProductCardWithActionsProps) => {
  const productCardRenderer = (
    isEnabled: boolean,
    isAvailable: boolean,
    unmetDependencies: string[],
    projectId: Project['id'],
    projectSlug: Project['slug'],
    slug: Product['slug'],
    name: string,
    isOrgAccount: boolean,
  ) => {
    if (isEnabled && isAvailable) {
      return (
        <Link type="button" to={`/${projectSlug}/stack/${slug}`}>
          <Stack direction="horizontal" space="small" align="center">
            Go to {name}
            <Icon color="white">
              <ChevronRight />
            </Icon>
          </Stack>
        </Link>
      )
    } else if (isAvailable && !isEnabled) {
      const projectUrl = `/${projectSlug}`
      return (
        <Stack space="small">
          {unmetDependencies?.length > 0 ? (
            <Message size="small" type="info">
              This product depends on having{' '}
              {unmetDependencies
                .map(
                  (dep, index) =>
                    `${
                      index >= 1 ? (index === unmetDependencies?.length - 1 ? ' and ' : ', ') : ''
                    }${dep}`,
                )
                .join('')}{' '}
              enabled in your project.
            </Message>
          ) : null}

          {!isOrgAccount && slug === 'hepic' && (
            <Message size="xsmall" type="info">
              hepic is only available in organization mode. Please{' '}
              <Link type="text" to={`${projectUrl}/organization/create`}>
                create organization
              </Link>{' '}
              or switch to organization to add hepic to your stack.
            </Message>
          )}

          {isOrgAccount && slug === 'hepic' && (
            <AddToProject
              projectId={projectId}
              projectSlug={projectSlug}
              productSlug={slug}
              isDisabled={unmetDependencies?.length > 0}
            />
          )}
          {slug !== 'hepic' && (
            <AddToProject
              projectId={projectId}
              projectSlug={projectSlug}
              productSlug={slug}
              isDisabled={unmetDependencies?.length > 0}
            />
          )}
        </Stack>
      )
    } else {
      return (
        <a
          target="_blank"
          href={`mailto:info@gigapipe.com?subject=We are interested in ${name}`}
          rel="noreferrer"
        >
          <Button color="neutral">Contact us</Button>
        </a>
      )
    }
  }

  return (
    <ProductCard
      name={name}
      slug={slug}
      description={description}
      tags={tags}
      isAvailable={isAvailable}
    >
      {productCardRenderer(
        isEnabled,
        isAvailable,
        unmetDependencies,
        projectId,
        projectSlug,
        slug,
        name,
        isOrgAccount,
      )}
    </ProductCard>
  )
}
