import { Suspense } from 'react'
import { type Integration } from 'api-client'

import { type ProductIconNames, ProductIcons } from '~/shared/ui/ProductIcons'

import { Icon } from '~/components/Icon'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

type IntegrationNameProps = {
  /**
   * Name of the integration
   */
  name: Integration['product']['name']
  /**
   * Slug of the integration, used to retrieve its icon from `ProductIcons`.
   */
  slug: Integration['product']['slug']
}

/**
 * Displays the integration name and icon.
 *
 * Used in the integration details page.
 */
export const IntegrationName = ({ name, slug }: IntegrationNameProps) => {
  const ProductIcon = ProductIcons[slug as ProductIconNames]

  return (
    <Stack direction="horizontal" space="small" align="center">
      <Icon size="xxxlarge">
        <Suspense>
          <ProductIcon />
        </Suspense>
      </Icon>
      <Text size="xxlarge">{name}</Text>
    </Stack>
  )
}
