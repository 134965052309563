import {
  isRouteErrorResponse,
  json,
  type LoaderFunctionArgs,
  useRouteError,
} from 'react-router-dom'
import { type QueryClient, useQuery } from '@tanstack/react-query'
import { authorize } from 'keycloak'
import { posthog } from 'posthog-js'

import { getUnmetDependencies, ProductCardWithActions } from '~/features/addProductToProject'

import { integrationsQuery } from '~/entities/integrations/base'
import { productsWithOnUseProjectsQuery } from '~/entities/products'
import { checkProjectSlugParam, useProjectIdParam } from '~/entities/projects'

import { authStore } from '~/shared/model/auth'
import { useDecodedToken } from '~/shared/model/auth/useAuth'
import { isApiError } from '~/shared/model/errors'
import { RouteErrorMessage } from '~/shared/ui/RouteErrorMessage'

import { Grid } from '~/components/Grid'
import { HeadTag } from '~/components/HeadTag'
import { PageHeader } from '~/components/PageHeader'
import { Stack } from '~/components/Stack'

export function loader(queryClient: QueryClient) {
  return async ({ params }: LoaderFunctionArgs) =>
    authorize(authStore, async () => {
      const { projectId } = checkProjectSlugParam(params)

      try {
        const [products, integrations] = await Promise.all([
          queryClient.ensureQueryData(productsWithOnUseProjectsQuery()),
          queryClient.ensureQueryData(integrationsQuery(projectId)),
        ])

        return json({ products, integrations })
      } catch (error) {
        if (isApiError(error) && error.type === 'data_query') {
          throw json(
            {
              message: 'We encountered an error while fetching some data.',
              description:
                'The application could not load the available products in the integrations page. Please reload the page and try again. If the issue persist please contact support.',
            },
            { status: 400 },
          )
        }
        throw error
      }
    })
}

export default function Integrations() {
  const { data: products } = useQuery(productsWithOnUseProjectsQuery())
  const { projectId, projectSlug } = useProjectIdParam()
  const { data: integrations } = useQuery({
    ...integrationsQuery(projectId),
    select: data => data.map(item => item.product.slug),
  })

  const decodedToken = useDecodedToken()
  const isOrgAccount = Boolean(decodedToken?.organization_id)

  return (
    <>
      <HeadTag tag="title" headId="title">
        {import.meta.env.MODE !== 'production' ? `(${import.meta.env.VITE_ENVIRONMENT}) ` : ''}
        Integrations - Gigapipe
      </HeadTag>

      <Stack>
        <PageHeader
          title="Choose Your Data Stack"
          subtitle="You can leverage market leading services to manage your data for event streaming,
            metrics and observability as well as data visualization."
        />
        <Grid columns={{ mobile: 1, tablet: 2, desktop: 3 }} space="medium" align="stretch">
          {products?.map(product => (
            <Grid.Item key={product.id} matchHeight>
              <ProductCardWithActions
                name={product.name}
                slug={product.slug}
                description={product.description}
                tags={product.tags}
                isAvailable={
                  product.slug === 'hepic'
                    ? posthog.isFeatureEnabled('hepic-beta-test')
                      ? product.isAvailable
                      : false
                    : product.isAvailable
                }
                isOrgAccount={isOrgAccount}
                isEnabled={product.projects.some(project => project.id === projectId)}
                projectId={projectId}
                projectSlug={projectSlug}
                unmetDependencies={getUnmetDependencies(product.dependencies, integrations)}
              />
            </Grid.Item>
          ))}
        </Grid>
      </Stack>
    </>
  )
}

export function ErrorBoundary() {
  const error = useRouteError() as Error

  if (isRouteErrorResponse(error))
    return <RouteErrorMessage message={error.data.message} description={error.data.description} />

  throw error
}
