import { type Product } from 'api-client'

import { downloadFile } from '~/shared/lib/downloadFile'
import { CopyButton } from '~/shared/ui/CopyButton'

import { Button } from '~/components/Button'
import { Input } from '~/components/Input'
import { Modal } from '~/components/Modal'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'

import { type ActionResponse } from '../model'

type InfoModalProps = {
  /**
   * Control if the modal is open or not.
   */
  isOpen: boolean
  /**
   * Name of the product used in the modal header.
   */
  name: Product['name']
  /**
   * Array of items to display using a readonly input.
   */
  items: ActionResponse['items']

  jsonDownLoad: boolean
  /**
   * Callback executed when closing the modal with the close button.
   */
  onClose: () => void
}

/**
 * Displays a modal with information about the just enabled product.
 */
export function InfoModal({ isOpen, name, items, onClose, jsonDownLoad }: InfoModalProps) {
  const handleDownload = () => {
    const data = items.reduce(
      (acc, item) => {
        acc[item.label] = item.value
        return acc
      },
      {} as Record<string, string>,
    )
    downloadFile(JSON.stringify(data, null, 2), {
      filename: `${name}-info.json`,
      format: 'json',
    })
  }

  return (
    <Modal isOpen={isOpen} isDismissable={false}>
      <Modal.Header align={'center'} title={`${name} integration created!`} />
      <Modal.Content>
        <Stack>
          {items?.length > 0 && <Text>Store this information carefully. </Text>}

          {jsonDownLoad && <Text>Copy or download it as JSON file.</Text>}
          {items.map(info => (
            <Input
              key={info.label}
              label={info.label}
              value={info.value}
              isReadOnly
              suffix={<CopyButton value={info.value} />}
            />
          ))}
          {jsonDownLoad && (
            <Button color="brand" onPress={handleDownload}>
              Download as JSON
            </Button>
          )}

          <Button color="transparent" onPress={onClose}>
            Close
          </Button>
        </Stack>
      </Modal.Content>
    </Modal>
  )
}
