import { useQuery } from '@tanstack/react-query'
import { type Integration, type Project } from 'api-client'

import { qrynCurrentCostQuery } from '~/entities/integrations/qryn'

import { formatPrice } from '~/i18n/utils'

import { Card } from '~/components/Card'
import { Loading } from '~/components/Loading'
import { Text } from '~/components/Text'

type QrynCostProps = {
  integrationStatus: Integration['status']
  projectId: Project['id']
}

export function QrynCost({ integrationStatus, projectId }: QrynCostProps) {
  const {
    data: currentCost,
    isError,
    isInitialLoading,
  } = useQuery(qrynCurrentCostQuery(projectId, integrationStatus))
  const isTrial = integrationStatus === 'trial'

  if (isInitialLoading)
    return (
      <Card>
        <Card.Header title="Current cost" />
        <Card.Section>
          <Loading size="xxlarge" />
        </Card.Section>
      </Card>
    )
  return (
    <Card>
      <Card.Header title="Current cost" />
      <Card.Section>
        {!isError && currentCost ? (
          <>
            <Text color="gigagreen" size="xxlarge" align="center">
              {formatPrice(currentCost?.totalCost ?? 0)}
            </Text>
            <Text color="gray600" size="small">
              Estimated cost for the next bill (incl. VAT).
            </Text>
          </>
        ) : (
          <Text color={isTrial ? 'gigagreen' : 'gray300'} size="xxlarge" align="center">
            {isTrial ? 'Trial' : 'Not available'}
          </Text>
        )}
      </Card.Section>
    </Card>
  )
}
