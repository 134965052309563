import { useFetcher } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { CREATE_PROJECT_RESOURCES_ROUTE } from '~/features/selectProject'

import { userOrganizationsQuery } from '~/entities/users'

import { useDecodedToken } from '~/shared/model/auth/useAuth'

import { Box } from '~/components/Box'
import { Button } from '~/components/Button'
import { Input } from '~/components/Input'
import { Link } from '~/components/Link'
import { Stack } from '~/components/Stack'
import { Text } from '~/components/Text'
import { Wizard } from '~/components/Wizard'

const Welcome = () => (
  <Box>
    <Box>
      <Text>Welcome to Gigapipe!</Text>
      <Text>
        We will guide you through the set up first steps.
        <br />
        Let&apos;s Go!
      </Text>
    </Box>
  </Box>
)

const CreateProject = () => {
  const fetcher = useFetcher()

  return (
    <Box>
      <Box>
        <Text>Create a new Project</Text>
        <Text>To start, we need to create a new Project. </Text>
      </Box>
      <Box>
        <fetcher.Form method="post" action={`/resources/${CREATE_PROJECT_RESOURCES_ROUTE}`}>
          <Box flexDirection="row" display="flex" gap="small" alignItems="flex-end" paddingX="none">
            <Box>
              <Input
                label="Name"
                key={fetcher.data?.fields?.name}
                aria-label="Add new project"
                placeholder="add new project"
                name="name"
                errorMessage={fetcher.data?.errors?.fieldErrors.name}
              />
            </Box>
            <Button
              aria-label="Create new project"
              type="submit"
              isLoading={fetcher.state === 'submitting'}
              isDisabled={fetcher.state === 'submitting'}
            >
              Create
            </Button>
          </Box>
        </fetcher.Form>
      </Box>
    </Box>
  )
}

const OrgWelcome = () => {
  const fetcher = useFetcher()

  const user = useDecodedToken()

  const { data: organization } = useQuery({
    ...userOrganizationsQuery(),
    select: data => data.find(org => org.id === String(user?.organization_id)),
  })

  return (
    <Box>
      <Stack direction="vertical" space="xlarge">
        <Stack space="small">
          <Text as="h1" size="xxxlarge">
            Welcome to {organization?.name}!
          </Text>
          <Text size="large">
            You are now part of the {organization?.name} organization. If you created the
            organization please create your first project below.
          </Text>
          <Text size="large">
            However if you&rsquo;ve been invited to {organization?.name} by another user then please
            contact your administrator and request to be added to an existing project.
          </Text>
        </Stack>
        <Stack space="small">
          <Text size="large">Create your first project</Text>
          <fetcher.Form method="post" action={`/resources/${CREATE_PROJECT_RESOURCES_ROUTE}`}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                gap: '8px',
              }}
            >
              <Input
                key={fetcher.data?.fields?.name}
                aria-label="Add new project"
                placeholder="add new project"
                name="name"
                errorMessage={fetcher.data?.errors?.fieldErrors.name}
              />
              <Button
                aria-label="Create new project"
                type="submit"
                isLoading={fetcher.state === 'submitting'}
                isDisabled={fetcher.state === 'submitting'}
              >
                Create
              </Button>
            </div>
          </fetcher.Form>
        </Stack>
        <Link
          to="/login"
          searchParams={{
            personalAccount: 'yes',
          }}
          type="text"
        >
          Switch to personal account
        </Link>
      </Stack>
    </Box>
  )
}

export const OnboardUser = () => {
  const orgLogin = window.sessionStorage.getItem('orgLogin') === 'true'

  if (orgLogin)
    return (
      <Wizard initialPage={1}>
        <OrgWelcome />
      </Wizard>
    )
  return (
    <Wizard initialPage={1}>
      <Welcome />
      <CreateProject />
    </Wizard>
  )
}
